<template>
  <div>
    <el-dialog
    :title="this.isUp== 1 ? '上传合同' : '查看合同'"
    :visible.sync="dialogVisible"
    width="34%"
    :before-close="handleClose"
  >
 <!-- 切换图片上传类型 -->
   <div class="tabs">
      <div  v-for="(item,index) in tabsList" :key="index" :class="index==tabsInex? 'itemCls' :''" @click="tabsClick(index)">
            {{item.title}}
      </div>
    </div>
    <el-upload
      class="upload-demo"
      action=""
      ref="AvatarUpload"
      :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG, WebP, .pdf'"
      list-type="picture-card"
      :auto-upload="false"
      :file-list="fileList"
      :on-change="upLoadImg"
      multiple
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <el-image
          ref="preview"
          style="height: 115px"
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          :preview-src-list="innerTableList"
          :initial-index=0
          alt=""
        />
        <span>{{ file.name }}</span>
        <span class="el-upload-list__item-actions">
          <span
            v-if="!file.isShow"
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- v-if="isUp == 1 ||file.isShow" -->
          <span

            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
          >
            <i class="el-icon-download"></i>
          </span>
          <!-- v-if="isUp == 1" -->
          <span
            class="el-upload-list__item-delete"
            @click="handleRemove(file, fileList)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
        <div style="width: 100%; height: 100%">
          <span style="padding-bottom: 0px">{{ file.name }}</span>
        </div>
      </div>
    </el-upload>
    <!-- v-if="isUp == 1" -->
    <div slot="footer" class="dialog-footer">
      <el-button style="margin-top: 20px" @click="back">取消</el-button>
      <el-button type="primary" @click="submit()"> 确定 </el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>

import { uploadFile } from "@/api/fileApi";
import { uploadContract, selectContractPic } from "@/api/add";
// 图片下载
import { download } from "@/api/ChargeUp";
// import { uploadContract, selectContractPic } from "@/api/add";
import {selectBackUpContract,uploadApprovalContract,selectApprovalOtherMaterials } from "@/api/FlowApproval.js";
export default {
  data() {
    return {
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      baseUrl: window.globalUrl.BASE_API,
      dialogVisible: true,
      dialogImageUrl: "",
      BgColor: false,
      disabled: false,
      isUpTyp:'',
      workFlowId:'',
      fileList: [],
      upImg: [],
      // xmbsm: "", //项目标识码
      // xmbh
      InitObj: {},
      approvalId:'',
      innerTableList: [],
      isUp:"",
      tabsInex:0,
      tabsList:[
        {
         title:'合同照片'
        },{
          title:'其他附件'
        }
      ],
    };
  },
  // watch: {
  //   fileList: {
  //     handler(newVal, oldVal) {
  //       console.log(newVal, "查看这个列表");
  //       // 检查每个文件对象的 name 属性
  //       // this.$nextTick(() => {
  //       // 在 DOM 更新之后执行回调函数
  //       newVal.forEach((file) => {
  //         if (file.name.toLowerCase().endsWith("pdf")) {
  //           // 修改符合条件的文件对象的 url 属性
  //           file.url =
  //             "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
  //           file.isShow = "true";
  //         }
  //         // });
  //         // console.log("fileList", this.fileList);
  //         // console.log("文件列表已更新");
  //         // console.log("新值:", newVal);
  //       });
  //       // 在这里可以根据实际需求执行相应操作
  //     },
  //     deep: true, // 深度监听数组内部元素的变化
  //   },
  // },
methods:{
   handleClose() {
      this.$emit("closeDialog", false);
    },
    back() {
      this.$emit("closeDialog", false);
    },
     //删除
     handleRemove(file, fileList) {
      //可用版本1
      // console.log(file, fileList);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.fileList.splice(index, 1);
    },
    //放大
    handlePictureCardPreview(file) {
           // 点击展示点击的图片
     console.log('预览的图片嘛',file)

      let abc = []
      this.fileList.forEach((its) => {
            if (!its.isShow) {
              abc.push(its.url);
            }
          });
    //保存修改完的图片预览列表
  let newList = []
  let i=''
  let num =abc.length // 原数组的长度
      abc.some((item, index) => {
    if (file.url === item) {
      console.log('相等')
        newList.push(item)
        i=index
    }
  })

      newList.push(...abc.slice(i + 1, num)) // 压入顺序在当前图片之后的图片url
      newList.push(...abc.slice(0, i)) // 压入顺序在当前图片之前的图片url
      abc = newList // 重新赋值
      this.innerTableList=newList
      this.$nextTick(() => {
          this.$refs.preview.clickHandler();
      });
      console.log( this.innerTableList,' this.innerTableList')
    },
    //上传的图片数组
    getViewUrl(arr) {
      let list = [];
      // console.log(arr, "查看这个arr");
      arr.forEach((item) => {
        list.push({
          FilesName: item.name,
          imgUrl: item.url2,
          uid: item.uid,
        });
      });
      return JSON.stringify(list);
    },
    // 改变回显的样式
    getViewUrl1(arr) {
      arr.forEach((item) => {
        item.name = item.FilesName;
        item.url = this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl;
        item.url2 = item.imgUrl;
      });
      this.fileList = arr;
    },
    //回显
    async Init(obj,isUp,isUpTyp,approvalId,workFlowId) {
      // console.log(isUp,'查看isUp是什么',obj,'查看查看isUp是什么')
      console.log(isUpTyp,'查看isUpTyp是什么22222222222222222222222222222222222222222222')
      this.InitObj = obj;
      this.isUp=isUp;
      this.isUpTyp=isUpTyp
      this.approvalId=approvalId
      this.workFlowId=workFlowId
      console.log(workFlowId,'查看workFlowId')
      console.log(this.workFlowId,'查看this.workFlowId')
      // if (isUp == 0) {
      //   this.$refs.AvatarUpload.$children[1].$el.style.display = 'none'
      // }
      // console.log(JSON.parse(JSON.stringify(obj)));
      // 如果status=1代表上传过

      // 审批之后的图片查看
      if(isUpTyp=="afterUp"){
        let params = {
          // xmbsm: obj.xmbsm,
          xmbh:obj.xmbh,
          approvalId:this.approvalId
        };
        let res = await selectBackUpContract(params);
        if(res.data.contractPicUrl){
            this.getViewUrl1(JSON.parse(res.data.contractPicUrl));
        }
      }else{
        //审批之前的图片查看
        let params = {
          xmbsm: obj.xmbsm,
        };
        let res = await selectContractPic(params);
        this.getViewUrl1(JSON.parse(res.data.picUrl));

      }


    },
    async upLoadImg(file, fileList) {
      if (file.name.toLowerCase().endsWith("pdf")) {
        // 修改符合条件的文件对象的 url 属性
        file.url =
          "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
        file.isShow = "true";
      }

      let res = await this.upload(file, fileList);
    },
    // 上传
    async upload(file, fileList) {
      // console.log(fileList, "fileList");
      let param = new FormData();
      param.append("file", file.raw);
      let res = await uploadFile(param);
      file.url2 = res.data.url;
      // console.log("file", file);
      this.fileList = fileList;
      console.log(this.fileList,'查看this.fileList')
    },
    //发布或编辑
    async submit() {
      let ImgUrl = this.getViewUrl(this.fileList);

      // 审批之后的上传图片
      if(this.isUpTyp=="afterUp"){
        let params = {
        // contractBaseInfoDtoList: [{ xmbsm: this.InitObj.xmbsm }],
        xmbsm: this.InitObj.xmbsm,
        xmbh:this.InitObj.xmbh,
        contractPicUrl: ImgUrl,
        workFlowId:this.workFlowId
      };
      let res = await uploadApprovalContract(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
        this.handleClose()
      } else {
        this.$message.error(res.msg);
      }
      this.$emit("closeDialog2", false);
      }else{
        //审批之前的上传图片
       let params = {
        contractBaseInfoDtoList: [{ xmbsm: this.InitObj.xmbsm }],
        picUrl: ImgUrl,
      };
      let res = await uploadContract(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
        this.handleClose()
      } else {
        this.$message.error(res.msg);
      }

      }

    },
    //下载
    async handleDownload(file) {
      console.log("下载");
      console.log(file);
      let itemList = file;
      console.log(this.baseUrl + "admin/file/get?ossFilePath=" + itemList.url2);

      let res = await download({
        ossFilePath:
          this.baseUrl + "admin/file/get?ossFilePath=" + itemList.url2,
      });
      if (res) {
        let blob = res;
        let ToName = itemList.name;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
    // 切换样式
    async tabsClick(index){
    console.log('这里切换执行了嘛')
      this.tabsInex=index
      this.fileList=[]
      if(index==0){
        console.log('继续查看合同照片')
      //  this.fileList=this.contractPhoto
      //   console.log('this.contractPhoto', this.contractPhoto)
      // let params = {
      //     // xmbsm: obj.xmbsm,
      //     xmbh:obj.xmbh,
      //     approvalId:obj.approvalId
      //   };
      //   let res = await selectBackUpContract(params);
      //   if(res.data.contractPicUrl){
      //       this.getViewUrl1(JSON.parse(res.data.contractPicUrl));
      //   }
       let params = {
          // xmbsm: obj.xmbsm,
          xmbh:this.InitObj.xmbh,
          approvalId:this.InitObj.approvalId
        };
        let res = await selectBackUpContract(params);
        if(res.data.contractPicUrl){
            this.getViewUrl1(JSON.parse(res.data.contractPicUrl));
        }

      }else{

       let params={
         xmbh:this.InitObj.xmbh,
         approvalId:this.InitObj.approvalId,
        }
       let res= await selectApprovalOtherMaterials(params)
        // this.fileList=this.otherPhoto
        if(res.data){
          this.getViewUrl1(JSON.parse(res.data));
        }
      }
    },
}
};
</script>
<style lang="less" scoped>
.tabs{
  width: 100%;
  height:100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
  .itemCls{
    display: block;
    background: #ccc;
  }
}
</style>
